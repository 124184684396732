import { config } from '@xtreamsrl/react-config';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import React from 'react';
import DownloadedDocumentsList from '../../../demo/views/DownloadedDocumentsList';
import LeavingCard from '../../components/LeavingCard';
import MultiplatformLogin from '../../components/MultiplatformLogin';
import { SidePanel } from '../../components/SidePanel';
import { Company, Target } from '../../types';

export const EntireProcessContainer: React.FC<{
  requestFrom: Company;
  target: Target;
}> = ({ requestFrom, target }) => {
  const [activeStepNumber, setActiveStepNumber] = React.useState(1);
  const [token, setToken] = React.useState<string>();

  function nextStep() {
    setActiveStepNumber((prev) => prev + 1);
  }

  function prevStep() {
    setActiveStepNumber((prev) => prev - 1);
  }

  return (
    <Box style={{ display: 'flex', minHeight: '100svh' }}>
      {(config.isDemo || activeStepNumber === 1) && (
        <SidePanel requestFrom={requestFrom} activeStep={activeStepNumber} />
      )}
      <Flex flex={4} backgroundColor="grey.1">
        {
          {
            0: null, // this is only to show that the authorisation to terms and conditions has been granted
            1: <MultiplatformLogin nextStep={nextStep} setToken={setToken} />,
            2: config.isDemo ? (
              <DownloadedDocumentsList prevStep={prevStep} token={token} />
            ) : (
              //   Unreachable when not in demo mode because the button to reach this step is removed from the UI
              <LeavingCard
                requestFrom={requestFrom}
                heading={`I documenti di ${target.name} sono stati condivisi con ${requestFrom.name}`}
                text="Ora puoi chiudere la pagina in sicurezza"
              />
            ),
          }[activeStepNumber]
        }
      </Flex>
    </Box>
  );
};
