import React, { PropsWithChildren } from 'react';
import { RoundedBox } from '../BoxVariants';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { FileIcon } from '@cassetto/icons';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import { toMoreDescriptiveName } from '../../../_shared/utils/utils';
import { DocumentFilterDisplay } from '../DocumentFilterDisplay';
import { DocumentStatusIcon } from '../DocumentStatusIcon';
import { Document, DownloadReportElement, PlatformStatus } from '../../types';

type Props = {
  index: number;
  outOf: number;
  doc: Document;
  docReport: DownloadReportElement | undefined;
  platformStatus: PlatformStatus;
  hasJustBeenAccessed: boolean;
};

const DocumentDetails: React.FC<PropsWithChildren<Props>> = (props) => {
  const docStatus = props.docReport?.documentTypeDownloadStatus.status;

  const docNumber =
    docStatus === 'ERROR'
      ? 0
      : props.docReport
        ? props.docReport.documentTypeDownloadStatus.partial +
          props.docReport.documentTypeDownloadStatus.completed
        : undefined;

  const getDocumentCountText = (docNumber: number | undefined): string => {
    if (docNumber === undefined) return '';
    if (docNumber === 0 && docStatus === 'COMPLETED') return '(Assente)';
    return `(${docNumber})`;
  };

  return (
    <RoundedBox
      index={props.index}
      total={props.outOf}
      paddingY="sm-4"
      paddingX="sm-8"
    >
      <Flex gap="sm-4" alignItems="flex-start">
        <FileIcon />
        <Flex flex={1} justifyContent="space-between" alignItems="center">
          <Flex gap="sm-6" direction="column">
            <Typography
              variant="body/base/semibold"
              style={{ display: 'ruby' }}
            >
              {toMoreDescriptiveName(props.doc.type)}&nbsp;
              <Typography variant="body/base/regular" color="grey.11">
                {getDocumentCountText(docNumber)}
              </Typography>
            </Typography>
            <DocumentFilterDisplay document={props.doc} />
          </Flex>
          <Flex gap="sm-4" direction="row" alignItems="center">
            <DocumentStatusIcon
              docStatus={docStatus}
              platfromStatus={props.platformStatus}
              hasJustBeenAccessed={props.hasJustBeenAccessed}
            />
          </Flex>
        </Flex>
      </Flex>
    </RoundedBox>
  );
};

DocumentDetails.displayName = 'DocumentDetails';
export default DocumentDetails;
