// eslint-disable-next-line no-restricted-imports
import {
  childrenLength,
  findChild,
  findChildren,
  FlexProps,
} from '@xtreamsrl/react-ui-kit';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import React, { PropsWithChildren } from 'react';
import useIsMobile from '../../hooks/useIsMobile';
import { PageHeading } from '../PageHeading';

type Style = FlexProps['style'];
export const PAGE_ID = 'page';
export const MODAL_ID = 'modal';

// TODO: maybe implementing fading for scrolling content on mobile
const PageContent: React.FC<PropsWithChildren<{ style?: Style }>> = ({
  children,
  style,
}) => {
  return (
    <Flex
      flex={1}
      direction="column"
      gap="sm-8"
      padding="sm-8"
      style={{ overflowY: { xs: 'auto', sm: 'auto', md: 'unset' }, ...style }}
    >
      {children}
    </Flex>
  );
};
PageContent.displayName = 'PageContent';

const PageFooter: React.FC<
  PropsWithChildren<{
    aboveButtonsContent?: React.ReactNode;
    variant?: PageProps['variant'];
  }>
> = ({ children, aboveButtonsContent = null, variant }) => {
  const isModal = variant === 'modal';
  const isMobile = useIsMobile();
  const buttons = findChildren(children, Button.displayName!);
  const buttonsNumber = childrenLength(buttons);

  if (buttonsNumber > 2) {
    throw new Error('Max 2 buttons are allowed in Page.Footer');
  }

  const components =
    buttonsNumber > 0
      ? [
          ...buttons,
          ...((isModal && buttonsNumber === 1) ||
          (buttonsNumber === 1 && !isMobile)
            ? []
            : Array.from({ length: 2 - buttonsNumber }, () => (
                <Box
                  key={`placeholder-${Math.random()}`}
                  width="100%"
                  height="55px"
                />
              ))),
        ]
      : [];

  return (
    <Flex
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      gap="sm-8"
      padding="sm-8"
    >
      {aboveButtonsContent}

      {components.length > 0 && (
        <Flex
          direction={isModal && !isMobile ? 'row-reverse' : 'column'}
          justifyContent={isModal && !isMobile ? 'space-between' : 'flex-start'}
          alignItems="center"
          gap="sm-8"
          width="100%"
          style={{
            minHeight: { xs: '20svh', sm: '20svh', md: 'auto' },
            button: { width: { xs: '100%', sm: '100%', md: 'fit-content' } },
          }}
        >
          {components}
        </Flex>
      )}
    </Flex>
  );
};
PageFooter.displayName = 'PageFooter';

export type PageProps = { style?: Style; variant?: 'modal' };

const PageComponent: React.FC<PropsWithChildren<PageProps>> = ({
  children,
  style,
  variant,
}) => {
  const Content = findChild(children, PageContent.displayName!);
  const Footer = findChild(children, PageFooter.displayName!);
  const Heading = findChild(children, PageHeading.displayName!);

  if (!Content) throw new Error('Page has no Page.Content');

  if (childrenLength(children) > 1 && !Footer && !Heading) {
    throw new Error(
      'Only PageHeading, Page.Content, Page.Footer are allowed as children',
    );
  }

  return (
    <Flex
      // TODO: expose id in Flex in ui-kit
      className={variant === 'modal' ? MODAL_ID : PAGE_ID}
      flex={1}
      direction="column"
      justifyContent="space-between"
      padding={variant === 'modal' ? 0 : [0, 0, 'md-8']}
      gap={[0, 0, 'md-8']}
      style={{
        minHeight: { xs: '100svh', sm: '100svh', md: 'unset' },
        maxHeight: { xs: '100svh', sm: '100svh', md: 'unset' },
        ...style,
      }}
    >
      {Heading}
      {Content}
      {Footer}
    </Flex>
  );
};

PageComponent.displayName = 'Modal';
const Page = Object.assign(PageComponent, {
  Content: PageContent,
  Footer: PageFooter,
});
export default Page;
