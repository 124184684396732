import { CieIcon, SpidIcon } from '@cassetto/icons';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { useState } from 'react';
import { Page } from '../../../_shared/components/Page';
import { PageHeading } from '../../../_shared/components/PageHeading';
import {
  Platforms,
  platformToDisplayName,
  SupportedOtherAuth,
  SupportedProvider,
} from '../../types';
import { ProviderCard } from './ProviderCard';
import { avaliableSpids } from './spidsConfig';

export function ProviderSelector({
  selectProvider,
  platform,
  goBack,
}: {
  selectProvider: (provider: SupportedProvider) => void;
  platform: Platforms;
  goBack: () => void;
}) {
  const [furtherSelectionNeeded, setFurtherSelectionNeeded] = useState(false);

  return (
    <Page>
      <PageHeading
        title={`Accedi ad ${platformToDisplayName[platform]}`}
        progress={25}
        goBack={goBack}
        subtitle={`
            Seleziona la modalità di accesso che preferisci. Per garantire la massima sicurezza, verrà richiesta un'autenticazione a due fattori.
            Assicurati di avere tutto il necessario per completare questo passaggio
          `}
      />

      <Page.Content>
        <ProviderCard
          logo={<img src="/assets/spid.png" alt="SPID logo" />}
          name="SPID"
          description="SPID, il Sistema Pubblico di Identità Digitale, è il sistema di accesso che
                  consente di utilizzare, con un'identità digitale unica, i servizi online della
                  Pubblica Amministrazione e dei privati accreditati. Se sei già in possesso di
                  un'identità digitale, accedi con le credenziali del tuo gestore. Se non hai ancora
                  un'identità digitale, richiedila ad uno dei gestori."
          action={
            <Box style={{ position: 'relative' }}>
              <Button
                leftIcon={
                  <Icon>
                    <SpidIcon />
                  </Icon>
                }
                color="info"
                style={{ textTransform: 'none', width: '100%' }}
                onClick={() =>
                  setFurtherSelectionNeeded(!furtherSelectionNeeded)
                }
              >
                Entra con SPID
              </Button>
              <Box
                style={{
                  display: furtherSelectionNeeded ? 'flex' : 'none',
                  flexDirection: 'column',
                  position: 'absolute',
                  zIndex: 1,
                  border: 'solid 1px #DFE3E6',
                  borderRadius: '6px',
                  marginTop: '8px',
                  height: '30svh',
                  width: '100%',
                  overflow: 'scroll',
                  background: (th) => th.palette.grey['1'],
                }}
              >
                {avaliableSpids.map((spid, index) => (
                  <Box
                    key={spid.provider}
                    onClick={() => selectProvider(spid.provider)}
                    backgroundColor="grey.1"
                    borderBottom={
                      index === avaliableSpids.length - 1
                        ? 0
                        : 'solid 1px #DFE3E6'
                    }
                    paddingTop="sm-7"
                    paddingBottom="sm-5"
                    paddingX="sm-5"
                    textAlign="center"
                    style={{
                      cursor: 'pointer',
                      ':hover': { backgroundColor: 'grey.3' },
                    }}
                  >
                    <img
                      src={spid.logo}
                      alt={`${spid.provider} logo`}
                      style={{
                        height: '25px',
                        maxWidth: '100%',
                        objectFit: 'contain',
                      }}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          }
        />

        <ProviderCard
          logo={<img src="/assets/cie.png" alt="CIE logo" />}
          name="CIE"
          description="La Carta di Identità Elettronica (CIE) è il documento personale che attesta
                  l'identità del cittadino.
                  Dotata di microprocessore, oltre a comprovare l'identità personale, permette
                  l'accesso ai servizi digitali della Pubblica Amministrazione"
          action={
            <Button
              variant="filled"
              color="info"
              leftIcon={
                <Icon>
                  <CieIcon />
                </Icon>
              }
              style={{ textTransform: 'none' }}
              onClick={() => selectProvider(SupportedOtherAuth.cie)}
            >
              Entra con CIE
            </Button>
          }
        />
      </Page.Content>
    </Page>
  );
}
