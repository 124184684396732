import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import React, { PropsWithChildren } from 'react';
import { Page } from '../../../_shared/components/Page';
import { PageHeading } from '../../../_shared/components/PageHeading';
import useIsMobile from '../../../_shared/hooks/useIsMobile';
import { RoundedBox } from '../../../requests/components/BoxVariants';
import Callout from '../../../requests/components/Callout/Callout';
import { useDocumentsListQuery } from '../../queries/getDocumentsListQuery';
import { fetchFile } from '../../queries/getFileQuery';
import { useRefreshPrevention } from '../../../_shared/hooks/useRefreshPrevention';

type Props = { token: string | undefined; prevStep: () => void };
const DownloadedDocumentsList: React.FC<PropsWithChildren<Props>> = ({
  token,
  prevStep,
}) => {
  useRefreshPrevention();
  const isMobile = useIsMobile();

  if (!token)
    throw new Error('DownloadedDocumentsList must be used using a token.');
  const { data, isLoading, isError } = useDocumentsListQuery(token);

  if (data) {
    return (
      <Page>
        <PageHeading
          goBack={prevStep}
          progress={100}
          title="Visualizzazione Documenti"
          subtitle='I documenti recuperati sono presenti nella lista che segue. Se
            desideri scaricare un documento, clicca sul pulsante "Scarica". Se
            questo non è presente, il documento originale non è disponibile.'
        />

        <Page.Content
          style={{ width: { xs: '100vw', sm: '100vw', md: '100%' } }}
        >
          <Box>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              paddingY="sm-3"
              paddingRight="sm-3"
              paddingLeft="sm-6"
            >
              <Flex gap="sm-6" flex={9} alignItems="center" overflow="hidden">
                <Typography
                  style={{ flex: 2 }}
                  variant="body/base/semibold"
                  textOverflow="ellipsis"
                >
                  File
                </Typography>
                <Typography
                  style={{ flex: 1 }}
                  variant="body/base/semibold"
                  textAlign="center"
                >
                  Anno
                </Typography>
              </Flex>
              <Box flex={2} />
            </Flex>
            {data
              .sort((a, b) => (b.hasFile ? 1 : 0) - (a.hasFile ? 1 : 0))
              .map((document, index) => (
                <RoundedBox
                  index={index}
                  total={data.length}
                  key={document.id}
                  sx={{ background: 'white' }}
                >
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    paddingY="sm-3"
                    paddingRight="sm-3"
                    paddingLeft="sm-6"
                  >
                    <Flex
                      gap="sm-6"
                      flex={9}
                      alignItems="center"
                      overflow="hidden"
                    >
                      <Typography
                        style={{ flex: 2, overflow: 'hidden' }}
                        variant="body/base/medium"
                        textOverflow="ellipsis"
                      >
                        {document.filename}
                      </Typography>
                      <Typography
                        style={{ flex: 1 }}
                        variant="body/base/regular"
                        textAlign="center"
                      >
                        {document.temporalReference.competence?.data.year
                          ? document.temporalReference.competence.data.year
                          : document.temporalReference.year}
                      </Typography>
                    </Flex>
                    <Box flex={2} textAlign="right">
                      {document.hasFile && (
                        <Button
                          {...(isMobile
                            ? {
                                onlyIcon: true,
                                style: { '.MuiButton-icon': { margin: 0 } },
                                icon: <Icon name="Download" />,
                              }
                            : {
                                onlyIcon: undefined,
                                children: 'Scarica il file',
                              })}
                          variant="tinted"
                          size="sm"
                          onClick={() =>
                            void fetchFile({
                              token,
                              documentId: document.id,
                            }).then((blob) => {
                              const url = window.URL.createObjectURL(blob);

                              // Create a temporary anchor element to trigger download
                              const a = window.document.createElement('a');
                              a.href = url;

                              // You can set the file name via the 'download' attribute, or it may come from the Content-Disposition header
                              a.download = document.filename; // Adjust the filename and extension as necessary

                              // Append the anchor element to the document body and trigger the download
                              window.document.body.appendChild(a);
                              a.click();

                              // Clean up: remove the anchor element and release the object URL
                              window.document.body.removeChild(a);
                              window.URL.revokeObjectURL(url);
                            })
                          }
                        />
                      )}
                    </Box>
                  </Flex>
                </RoundedBox>
              ))}
          </Box>
        </Page.Content>

        <Page.Footer>
          <Button variant="plain" color="grey" onClick={prevStep}>
            Torna Indietro
          </Button>
        </Page.Footer>
      </Page>
    );
  }

  if (isError)
    return (
      <Page>
        <PageHeading
          goBack={prevStep}
          progress={100}
          title="Visualizzazione Documenti"
          subtitle='I documenti recuperati sono presenti nella lista che segue. Se
            desideri scaricare un documento, clicca sul pulsante "Scarica". Se
            questo non è presente, il documento originale non è disponibile.'
        />

        <Page.Content>
          <Callout status="error" text="Errore">
            <Typography variant="body/xs/regular" color="grey.11">
              Si è verificato un errore durante il recupero dei documenti. Se
              hai aggiornato la pagina, hai perso l'accesso ai documenti
              scaricati.
            </Typography>
          </Callout>
        </Page.Content>
        <Page.Footer>
          <Button variant="plain" color="grey" onClick={prevStep}>
            Torna Indietro
          </Button>
        </Page.Footer>
      </Page>
    );

  if (isLoading) return <Box>Caricamento...</Box>;
};

DownloadedDocumentsList.displayName = 'DownloadedDocumentsList';
export default DownloadedDocumentsList;
