import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import React, { PropsWithChildren } from 'react';

const FullScreenCard: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Flex
      direction="column"
      alignItems="center"
      style={{
        minHeight: '100svh',
        minWidth: '100%',
      }}
      backgroundColor="brand.12"
      padding={[0, 0, 'xl-2', 'xl-2']}
    >
      <Flex
        flex={1}
        direction="column"
        width="100%"
        justifyContent="center"
        backgroundColor="background.default"
        border="1px solid"
        padding={[0, 0, 'md-8']}
        paddingTop={[0, 0, 'xl-1']}
        style={(th) => ({
          borderColor: 'grey.1',
          borderRadius: '8px',
          maxWidth: '900px',
          [th.breakpoints.down('md')]: {
            borderRadius: '0px',
            minHeight: '100svh',
          },
        })}
      >
        {children}
      </Flex>
    </Flex>
  );
};

FullScreenCard.displayName = 'FullScreenCard';
export default FullScreenCard;
