import { useEffect, useState } from 'react';
import { MODAL_ID, PAGE_ID } from '../components/Page/Page';

export const usePagePortal = (variant?: typeof MODAL_ID) => {
  const [pageEl, setPageEl] = useState<Element | null>(null);

  useEffect(() => {
    setPageEl(document.getElementsByClassName(variant ?? PAGE_ID).item(0));
  }, [variant]);

  return pageEl;
};
