import { ErrorIcon, SuccessIcon } from '@cassetto/icons';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { Spinner } from '@xtreamsrl/react-ui-kit/Spinner';
import { styled } from '@xtreamsrl/react-ui-kit/styles';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import React from 'react';
import {
  AuthorisationAndFinalisationInnerState,
  AuthorisationAndFinalisationInnerStep,
} from '../../../hooks/useAuthorisationAndFinalisationEventsHandler';

const BlackSpinner = styled(Spinner)`
  color: black;
`;

const finalisationValueBasedOnState = {
  authentication: {
    error: {
      iconComponent: (
        <Icon>
          <ErrorIcon />
        </Icon>
      ),
      title: 'Autenticazione fallita',
      description: 'Si è verificato un errore durante l’autenticazione.',
    },
    loading: {
      iconComponent: <BlackSpinner size={24} />,
      title: 'Autenticazione in corso...',
      description:
        'Stiamo verificando la tua identità. L’autenticazione a due fattori garantisce la massima sicurezza',
    },
    todo: {
      iconComponent: <Box style={{ minWidth: '24px' }}></Box>,
      title: 'Autenticazione',
      description:
        'Stiamo verificando la tua identità. L’autenticazione a due fattori garantisce la massima sicurezza',
    },
    success: {
      iconComponent: (
        <Icon>
          <SuccessIcon />
        </Icon>
      ),
      title: 'Autenticazione avvenuta con successo',
      description:
        'Stiamo verificando la tua identità. L’autenticazione a due fattori garantisce la massima sicurezza',
    },
  },
  authorisation: {
    error: {
      iconComponent: (
        <Icon>
          <ErrorIcon />
        </Icon>
      ),
      title: 'Autorizzazione dei dati fallita',
      description:
        "Stiamo autorizzando l'accesso alle informazioni necessarie.\n" +
        'Questo processo assicura che i tuoi dati vengano condivisi in modo sicuro',
    },
    loading: {
      iconComponent: <BlackSpinner size={24} />,
      title: 'Autorizzazione dei dati in corso...',
      description:
        "Stiamo autorizzando l'accesso alle informazioni necessarie.\n" +
        'Questo processo assicura che i tuoi dati vengano condivisi in modo sicuro',
    },
    todo: {
      iconComponent: <Box style={{ minWidth: '24px' }}></Box>,
      title: 'Autorizzazione dei dati',
      description:
        "Stiamo autorizzando l'accesso alle informazioni necessarie.\n" +
        'Questo processo assicura che i tuoi dati vengano condivisi in modo sicuro',
    },

    success: {
      iconComponent: (
        <Icon>
          <SuccessIcon />
        </Icon>
      ),
      title: 'Autorizzazione dei dati avvenuta con successo',
      description:
        "Stiamo autorizzando l'accesso alle informazioni necessarie.\n" +
        'Questo processo assicura che i tuoi dati vengano condivisi in modo sicuro',
    },
  },
  headersAndCookies: {
    error: {
      iconComponent: (
        <Icon>
          <ErrorIcon />
        </Icon>
      ),
      title: 'Elaborazione dei dati fallita',
      description:
        'Stiamo elaborando i tuoi dati di accesso.\n' +
        "L'operazione potrebbe richiedere alcuni minuti.",
    },
    loading: {
      iconComponent: <BlackSpinner size={24} />,
      title: 'Elaborazione dei dati in corso...',
      description:
        'Stiamo elaborando i tuoi dati di accesso.\n' +
        "L'operazione potrebbe richiedere alcuni minuti.",
    },
    todo: {
      iconComponent: <Box style={{ minWidth: '24px' }}></Box>,
      title: 'Elaborazione dei dati',
      description:
        'Stiamo elaborando i tuoi dati di accesso.\n' +
        "L'operazione potrebbe richiedere alcuni minuti.",
    },
    success: {
      iconComponent: (
        <Icon>
          <SuccessIcon />
        </Icon>
      ),
      title: 'Elaborazione dei dati avvenuta con successo',
      description:
        'Stiamo elaborando i tuoi dati.\n' +
        "L'operazione potrebbe richiedere alcuni minuti.",
    },
  },
} satisfies Record<
  AuthorisationAndFinalisationInnerStep,
  Record<
    AuthorisationAndFinalisationInnerState,
    {
      iconComponent: React.JSX.Element | Element;
      title: string;
      description: string;
    }
  >
>;

type FinalisationStepProps = {
  step: 'authentication' | 'authorisation' | 'headersAndCookies';
  state: AuthorisationAndFinalisationInnerState;
};

const FinalisationStep: React.FC<FinalisationStepProps> = ({ step, state }) => {
  const { iconComponent, title, description } =
    finalisationValueBasedOnState[step][state];

  return (
    <Flex
      style={{
        flexDirection: { xs: 'column', sm: 'column', md: 'row' },
        alignItems: { xs: 'center', sm: 'center', md: 'flex-start' },
        width: { xs: '70vw', sm: '70vw', md: '100%' },
        '> *:first-child, > div:first-child > span': {
          width: { xs: '48px', sm: '48px', md: '24px' },
          height: { xs: '48px', sm: '48px', md: '24px' },
        },
      }}
      gap="sm-6"
      paddingTop="sm-2"
      paddingBottom="sm-1"
    >
      {iconComponent}
      <Flex
        direction="column"
        gap="sm-2"
        style={{
          '> *': { textAlign: { xs: 'center', sm: 'center', md: 'left' } },
        }}
      >
        <Typography variant="body/base/semibold">{title}</Typography>
        <Typography variant="body/sm/regular" color="grey">
          {description}
        </Typography>
      </Flex>
    </Flex>
  );
};

FinalisationStep.displayName = 'FinalisationStep';
export default FinalisationStep;
