import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import React, { PropsWithChildren } from 'react';
import { Company } from '../../types';
import FullScreenCard from '../FullScreenCard';

interface LeavingCardProps {
  requestFrom: Company;
  heading: string;
  text: string;
}

const LeavingCard: React.FC<PropsWithChildren<LeavingCardProps>> = ({
  requestFrom,
  heading,
  text,
  children,
}) => {
  return (
    <FullScreenCard>
      <Flex
        height="100%"
        direction="column"
        alignItems="center"
        justifyContent="center"
        gap="md-5"
      >
        <img
          alt={`Logo ${requestFrom.name}`}
          style={{
            height: '40px',
            objectFit: 'contain',
          }}
          src={requestFrom.logo}
        />
        <Flex direction="column" gap="sm-4">
          <Typography variant="body/xl/semibold" textAlign="center">
            {heading}
          </Typography>
          <Typography
            variant="body/base/regular"
            textAlign="center"
            color="grey.11"
          >
            {text}
          </Typography>
        </Flex>
        <Flex
          direction="column"
          alignItems="center"
          justifyContent="flex-start"
          width="100%"
        >
          {children}
        </Flex>
      </Flex>
    </FullScreenCard>
  );
};

LeavingCard.displayName = 'LeavingCard';
export default LeavingCard;
