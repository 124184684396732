import { FormTextInputBasic } from '@xtreamsrl/react-form-input-kit/FormTextInputBasic';
import { FormProvider } from '@xtreamsrl/react-forms';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Page } from '../../../../_shared/components/Page';
import { MODAL_ID } from '../../../../_shared/components/Page/Page';
import { usePagePortal } from '../../../../_shared/hooks/usePagePortal';
import { useOtpInputStepEventsHandler } from '../../../hooks/useOtpInputStepEventsHandler';

const OtpInputStep: React.FC = () => {
  const { loading, otpForm, submit } = useOtpInputStepEventsHandler();
  const modalPageEl = usePagePortal(MODAL_ID);

  useEffect(() => {
    otpForm.formProps.setFocus('otp');
  }, [otpForm.formProps]);

  return (
    <FormProvider enableDevTools={false} {...otpForm.formProps}>
      <Flex direction="column" flex={1} justifyContent="center" gap="md-1">
        <FormTextInputBasic name="otp" placeholder="OTP" disabled={loading} />

        {modalPageEl &&
          createPortal(
            <Page.Footer>
              <Button
                type="submit"
                variant="outlined"
                loading={loading}
                color="grey"
                style={{
                  color: 'black',
                  width: { xs: '100%', sm: '100%', md: 'auto' },
                }}
                onClick={(e) => {
                  e.preventDefault();
                  submit().catch((error) => {
                    console.error('Failed to submit form:', error);
                  });
                }}
              >
                Conferma
              </Button>
            </Page.Footer>,
            modalPageEl,
          )}
      </Flex>
    </FormProvider>
  );
};

OtpInputStep.displayName = 'OtpInputStep';
export default OtpInputStep;
