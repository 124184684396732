import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Modal } from '@xtreamsrl/react-ui-kit/Modal';
import { styled } from '@xtreamsrl/react-ui-kit/styles';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import React from 'react';
import { Page } from '../../../_shared/components/Page';
import { PageHeading } from '../../../_shared/components/PageHeading';
import {
  getProviderLogos,
  providerToDisplayName,
} from '../../../_shared/utils/utils';
import { LoginContext, useLogin } from '../../hooks/useLogin';
import {
  LoginStep,
  Platforms,
  platformToDisplayName,
  SupportedOtherAuth,
} from '../../types';
import { AuthenticationStep } from './AuthenticationStep';
import { AuthorisationAndFinalisationStep } from './AuthorisationAndFinalisationStep';
import LoginFatalError from './LoginFatalError';
import { OtpInputStep } from './OtpInputStep';
import { ProviderSelector } from './ProviderSelector';
import { TwoFactorSelectionStep } from './TwoFactorSelectionStep';
import { useRefreshPrevention } from '../../../_shared/hooks/useRefreshPrevention';

const ProviderLogosHeading = styled(Flex)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing('sm-8'),
    paddingTop: theme.spacing('md-1'),
    paddingBottom: theme.spacing('md-1'),
    borderBottom: '1px solid #F1F3F5',
    backgroundColor: 'white',
  },
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing('sm-8'),
  paddingTop: theme.spacing('md-4'),
  paddingBottom: theme.spacing('sm-8'),
  borderBottom: 'none',
  backgroundColor: 'none',
}));

type Props = {
  onLoginSuccess: (token: string) => void;
  onLoginFailure: () => void;
  unsetPlatform: () => void;
  platform: Platforms;
};

const Login: React.FC<Props> = ({
  onLoginSuccess,
  onLoginFailure,
  platform,
  unsetPlatform,
}) => {
  const {
    selectedProvider,
    selectProvider,
    step,
    setStep,
    twoFactorHandling,
    setTwoFactorHandling,
    socket,
    retry,
  } = useLogin(platform);

  const logos = getProviderLogos(selectedProvider);
  useRefreshPrevention(step <= LoginStep.authentication);

  return (
    <>
      {!selectedProvider ? (
        <ProviderSelector
          selectProvider={selectProvider}
          platform={platform}
          goBack={unsetPlatform}
        />
      ) : (
        <Page style={{ padding: 0 }}>
          <PageHeading title="Entra con SPID" progress={50} hideOnDesktop />
          <Page.Content style={{ padding: 0 }}>
            <ProviderLogosHeading>
              <img
                src={logos?.chosenProvider}
                style={{ height: '44px' }}
                alt={`Logo ${selectedProvider === SupportedOtherAuth.cie ? 'CIE' : 'SPID'}`}
              />
              {logos?.specificLogo && (
                <img
                  style={{ height: '50px' }}
                  src={logos.specificLogo}
                  alt={`Logo dello SPID provider ${providerToDisplayName[selectedProvider]}`}
                />
              )}
            </ProviderLogosHeading>
            <LoginContext.Provider
              value={{
                socket,
                setStep,
                retry,
                platform,
                provider: selectedProvider,
              }}
            >
              <Flex
                direction="column"
                padding={['sm-8', 'sm-8', 'lg-2']}
                gap={['md-4', 'md-4', 'xl-2']}
              >
                <Flex direction="column" gap="sm-4">
                  <Typography
                    variant="body/xl/regular"
                    textAlign="left"
                    textTransform="uppercase"
                    color="grey.11"
                  >
                    RICHIESTA DI ACCESSO {selectedProvider} DI LIVELLO 2 DA
                  </Typography>
                  <Typography variant="header/sm/semibold" textAlign="left">
                    {platformToDisplayName[platform]}
                  </Typography>
                </Flex>

                <AuthenticationStep
                  selectedProvider={selectedProvider}
                  setTwoFactorHandling={setTwoFactorHandling}
                />

                <Modal
                  open={
                    step >= LoginStep.twoFactorSelection ||
                    step === LoginStep.error
                  }
                  onClose={() => {}} // the modal should not be closed clicking outside its content
                  showCloseButton={false}
                >
                  <Modal.Content>
                    {step >= LoginStep.authentication && (
                      <Page variant="modal">
                        <PageHeading
                          variant="modal"
                          title="Autenticazione a Due Fattori (2FA)"
                          subtitle={
                            step <= LoginStep.otpInput
                              ? 'Conferma la tua identità attraverso un secondo metodo di verifica.'
                              : 'Ti preghiamo di non chiudere o ricaricare la pagina mentre uno degli step è in esecuzione.\nAltrimenti la procedura di login verrà interrotta.'
                          }
                          progress={75}
                        />

                        <Page.Content>
                          {step === LoginStep.twoFactorSelection && (
                            <TwoFactorSelectionStep
                              twoFactorHandling={twoFactorHandling}
                            />
                          )}
                          {step === LoginStep.otpInput && <OtpInputStep />}
                          {step >= LoginStep.authorisationAndFinalisation && (
                            <AuthorisationAndFinalisationStep
                              onLoginSuccess={onLoginSuccess}
                              onLoginFailure={onLoginFailure}
                              selectedProvider={selectedProvider}
                            />
                          )}
                        </Page.Content>
                      </Page>
                    )}
                    {step === LoginStep.error && (
                      <LoginFatalError
                        selectedProvider={selectedProvider}
                        retry={retry}
                        onLoginFailure={onLoginFailure}
                      />
                    )}
                  </Modal.Content>
                </Modal>
              </Flex>
            </LoginContext.Provider>
          </Page.Content>
        </Page>
      )}
    </>
  );
};

Login.displayName = 'Login';

export default Login;
