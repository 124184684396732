import { useQuery } from '@tanstack/react-query';
import { optionsBuilder } from '../asyncHooks/getRequestInfo';
import { Platforms } from '../types';
import { shouldRefetch } from '../utils';
import { useRequestId } from './useRequestId';

const REFETCH_INTERVAL = 3000;

export const useRequestData = ({
  lastSuccessfullyAccessedPlatform,
}: {
  lastSuccessfullyAccessedPlatform: Platforms | undefined;
}) => {
  const requestId = useRequestId();

  const { data } = useQuery({
    ...optionsBuilder(requestId),
    select: (data) => ({
      isLinkShareable: data.isLinkShareable,
      requestFrom: data.requestFrom,
      platformAndDocuments: data.platformAndDocuments,
      platformStatus: data.platformStatus,
      downloadReport: data.downloadReport,
    }),
    staleTime: REFETCH_INTERVAL,
    refetchInterval: ({ state: { data } }) => {
      if (
        data &&
        shouldRefetch({
          lastAccessedPlatform: lastSuccessfullyAccessedPlatform,
          platformStatuses: data.platformStatus,
          platformAndDocuments: data.platformAndDocuments,
        })
      ) {
        return REFETCH_INTERVAL;
      }
      return false;
    },
  });

  if (!data) throw new Error('Request is required');

  return data;
};
