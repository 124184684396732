import React from 'react';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';

interface Props {
  content: string;
  color: 'green' | 'blue';
}

const DemoFooter: React.FC<Props> = ({ content, color }) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      backgroundColor={color === 'blue' ? 'info.5' : 'success.5'}
      width="100%"
      style={(th) => ({
        minHeight: '3em',
        position: 'sticky',
        zIndex: 3,
        bottom: 0,
        left: 0,
        [th.breakpoints.down('md')]: {
          minHeight: '2em',
        },
      })}
    >
      <Typography variant="body/base/semibold">{content}</Typography>
    </Flex>
  );
};

DemoFooter.displayName = 'DemoFooter';
export default DemoFooter;
