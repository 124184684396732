import React, { PropsWithChildren } from 'react';
import { FormProvider } from '@xtreamsrl/react-forms';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { FormCheckboxLabel } from '@xtreamsrl/react-form-input-kit/FormCheckboxLabel';
import { toMoreDescriptiveName } from '../../../_shared/utils/utils';
import { useDocumentSelectorForm } from '../../hooks/useDocumentSelectorForm';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import Callout from '../../../requests/components/Callout/Callout';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import {
  Platforms,
  platformToDisplayName,
  RequestDocumentType,
} from '../../../requests/types';
import { DocumentFilterDisplay } from '../../../requests/components/DocumentFilterDisplay';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { createPortal } from 'react-dom';
import { usePagePortal } from '../../../_shared/hooks/usePagePortal';
import { Page } from 'src/_shared/components/Page';

type Props = {
  onSubmit: (selectedDocs: RequestDocumentType[]) => void;
  isError: boolean;
  isPending: boolean;
  back: () => void;
};

const DocumentSelectorForm: React.FC<PropsWithChildren<Props>> = ({
  onSubmit,
  isError,
  isPending,
  back,
}) => {
  const { docsForm, selectableDocuments, handleSubmit } =
    useDocumentSelectorForm(onSubmit);
  const pagePortalEl = usePagePortal('modal');

  const groupedDocuments = selectableDocuments.reduce(
    (acc, doc) => {
      if (!acc[doc.platform]) {
        acc[doc.platform] = [];
      }
      acc[doc.platform].push(doc);
      return acc;
    },
    {} as Record<string, typeof selectableDocuments>,
  );

  const sortedPlatforms = Object.keys(groupedDocuments).sort();

  return (
    <FormProvider enableDevTools={false} {...docsForm.formProps}>
      <form
        style={{ width: '100%' }}
        onSubmit={(event) => {
          handleSubmit(event).catch((error) => {
            console.error('Error sending credentials', error);
          });
        }}
      >
        <Flex direction="column" gap="sm-4">
          <Typography variant="body/xl/semibold">
            Personalizza il set documentale in pochi click
          </Typography>
          <Typography variant="body/base/regular">
            Scegli le tipologie di documenti che desideri includere nella demo e
            spuntale dalla lista. Puoi anche rimuovere la spunta dai documenti
            che non ti interessano, per escluderli facilmente dalla tua
            richiesta. CassettoAPI genererà un set documentale personalizzato,
            costruito su misura in base alle tue preferenze.
          </Typography>
          <Flex
            direction="column"
            gap="sm-2"
            marginBottom="sm-4"
            justifyContent="center"
          >
            {sortedPlatforms.map((platform) => (
              <React.Fragment key={platform}>
                <Typography variant="body/lg/semibold">
                  {platformToDisplayName[platform as Platforms]}
                </Typography>
                {groupedDocuments[platform].map((selectableItem) => (
                  <Flex
                    gap="sm-4"
                    alignItems="center"
                    key={selectableItem.doc.type}
                  >
                    <FormCheckboxLabel
                      label={toMoreDescriptiveName(selectableItem.doc.type)}
                      name={selectableItem.doc.type}
                      size="sm"
                    />
                    <DocumentFilterDisplay document={selectableItem.doc} />
                  </Flex>
                ))}
                <hr />
              </React.Fragment>
            ))}
          </Flex>
          {isError && (
            <Box marginTop="sm-2" marginBottom="md-1">
              <Callout status="error" text="Errore">
                <Typography variant="body/sm/regular">
                  Si è verificato un errore durante la creazione della
                  richiesta. Riprova...
                </Typography>
              </Callout>
            </Box>
          )}
          {pagePortalEl &&
            createPortal(
              <Page.Footer variant="modal">
                <Button
                  loading={isPending}
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit().catch((error) => {
                      console.error('Failed to submit form:', error);
                    });
                  }}
                >
                  Avanti
                </Button>
                <Button
                  variant="plain"
                  onClick={(e) => {
                    e.preventDefault();
                    back();
                  }}
                >
                  Indietro
                </Button>
              </Page.Footer>,
              pagePortalEl,
            )}
        </Flex>
      </form>
    </FormProvider>
  );
};

DocumentSelectorForm.displayName = 'DocumentSelectorForm';
export default DocumentSelectorForm;
