import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import React from 'react';
import { Document, DownloadReport, PlatformStatus } from '../../types';
import { DocumentDetails } from '../DocumentDetails';

type Props = {
  status: PlatformStatus;
  documents: Document[];
  downloadReport: DownloadReport;
  hasJustBeenAccessed: boolean;
};

const DocumentsList: React.FC<Props> = (props) => {
  const { documents, status, downloadReport, hasJustBeenAccessed } = props;
  return (
    <Flex direction="column">
      {documents.map((doc, index) => {
        const docReport =
          downloadReport &&
          downloadReport.find((item) => item.type === doc.type);
        return (
          <DocumentDetails
            key={doc.type}
            index={index}
            outOf={documents.length}
            doc={doc}
            docReport={docReport}
            platformStatus={status}
            hasJustBeenAccessed={hasJustBeenAccessed}
          />
        );
      })}
    </Flex>
  );
};

DocumentsList.displayName = 'DocumentsList';
export default DocumentsList;
