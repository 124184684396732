import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import { Page } from '../../../../_shared/components/Page';
import { PageHeading } from '../../../../_shared/components/PageHeading';
import useIsMobile from '../../../../_shared/hooks/useIsMobile';
import { providerToDisplayName } from '../../../../_shared/utils/utils';
import { SupportedProvider } from '../../../types';
import Callout from '../../Callout/Callout';
import { FinalisationStep } from '../AuthorisationAndFinalisationStep';

export function LoginFatalError(props: {
  selectedProvider: SupportedProvider;
  retry: () => void;
  onLoginFailure: () => void; // this is an error component, so 'successful' cannot be true
}) {
  const isMobile = useIsMobile();

  return (
    <Page variant="modal" style={{ height: '100%' }}>
      <PageHeading
        variant="modal"
        title="Autenticazione a Due Fattori (2FA)"
        subtitle="Conferma la tua identità attraverso un secondo metodo di verifica."
        progress={75}
      />

      <Page.Content>
        <Flex
          flex={1}
          alignItems="center"
          direction="column"
          justifyContent="center"
          gap="sm-6"
        >
          <FinalisationStep step="authentication" state="error" />
          {!isMobile && (
            <>
              <FinalisationStep step="authorisation" state="todo" />
              <FinalisationStep step="headersAndCookies" state="todo" />
            </>
          )}

          <Callout
            status="error"
            text="Si è verificato un errore durante l'accesso alla piattaforma"
          >
            <Typography variant="body/sm/regular">
              Questo potrebbe essere dovuto a: provider{' '}
              <strong>
                {providerToDisplayName[props.selectedProvider]} momentaneamente
                non disponibile
              </strong>
              , <strong>credenziali errate</strong> o{' '}
              <strong>mancanza dei permessi</strong> per accedere alla
              piattaforma o al dato richiesto.
            </Typography>
          </Callout>
        </Flex>
      </Page.Content>

      <Page.Footer variant="modal">
        {/* The style is required due to the fact that mui adds absolute positioning for buttons inside the modal */}
        <Button
          size="lg"
          color="info"
          onClick={props.retry}
          style={{
            position: 'relative !important',
            top: '0 !important',
            right: '0 !important',
          }}
        >
          Riprova
        </Button>
        <Button
          variant="plain"
          size="lg"
          color="grey"
          onClick={props.onLoginFailure}
          leftIcon={<Icon name="ArrowLeft" />}
          style={{
            position: 'relative !important',
            top: '0 !important',
            right: '0 !important',
          }}
        >
          Accedi ad un'altra piattaforma
        </Button>
      </Page.Footer>
    </Page>
  );
}
