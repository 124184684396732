import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import React, { useMemo } from 'react';
import { Page } from '../../../../_shared/components/Page';
import { PageHeading } from '../../../../_shared/components/PageHeading';
import {
  isInFinalStatus,
  toMoreDescriptiveName,
} from '../../../../_shared/utils/utils';
import Callout from '../../../components/Callout/Callout';
import { DocumentsList } from '../../../components/DocumentsList';
import { useRequestData } from '../../../hooks/useRequestData';
import {
  Platforms,
  platformStatus,
  platformToDisplayName,
  platformToLogo,
} from '../../../types';

type Props = {
  setActivePlatform: (platform: Platforms | undefined) => void;
  platform: Platforms;
  lastSuccessfullyAccessedPlatform: Platforms | undefined;
};
const PlatformDetailsView: React.FC<Props> = ({
  setActivePlatform,
  platform,
  lastSuccessfullyAccessedPlatform,
}) => {
  const data = useRequestData({
    lastSuccessfullyAccessedPlatform,
  });

  const platformData = useMemo(() => {
    return data.platformAndDocuments.find((p) => p.platform === platform);
  }, [platform, data]);

  // TODO: try and extract this in a page component
  return (
    platformData && (
      <Page>
        <PageHeading
          title="Documenti Recuperabili"
          leftButton={
            <Button
              onlyIcon
              variant="plain"
              color="grey"
              icon={<Icon name="ChevronLeft" />}
              onClick={() => setActivePlatform(undefined)}
            />
          }
        />

        <Page.Content>
          <Flex direction="column" gap="sm-8">
            <Flex width="100%" justifyContent="space-between">
              <img
                src={platformToLogo[platform]}
                alt={`Logo ${platformToDisplayName[platform]}`}
                style={{
                  width: '44px',
                  height: '44px',
                }}
              />
            </Flex>

            <Flex direction="column">
              <Typography variant="body/base/semibold">
                {platformToDisplayName[platform]}
              </Typography>
              <Typography variant="body/sm/regular" color="grey.11" noWrap>
                {platformData.documents
                  .map((doc) => toMoreDescriptiveName(doc.type))
                  .join(', ')}
              </Typography>
            </Flex>
          </Flex>

          <DocumentsList
            downloadReport={data.downloadReport}
            documents={platformData.documents}
            status={data.platformStatus[platform]!.status}
            hasJustBeenAccessed={
              platformData.platform === lastSuccessfullyAccessedPlatform
            }
          />

          {isInFinalStatus(data.platformStatus[platform]!.status) &&
            data.platformStatus[platform]!.status !==
              platformStatus.DOWNLOAD_COMPLETED && (
              <Callout
                status={
                  data.platformStatus[platform]!.status ===
                  platformStatus.DOWNLOAD_PARTIAL
                    ? 'warning'
                    : 'error'
                }
                text="Errore nel recupero dei documenti"
              >
                <Typography variant="body/sm/regular">
                  Non è stato possibile recuperare tutti i documenti richiesti.
                  Tuttavia,{' '}
                  <b>
                    la procedura potrà proseguire anche con la documentazione
                    parziale.
                  </b>{' '}
                  {data.requestFrom.name} ti contatterà in caso fosse necessario
                  fornire ulteriori informazioni.
                </Typography>
              </Callout>
            )}
        </Page.Content>

        <Page.Footer>
          {!isInFinalStatus(data.platformStatus[platform]!.status) &&
          data.platformStatus[platform]!.status !==
            platformStatus.DOWNLOADING &&
          !(platformData.platform === lastSuccessfullyAccessedPlatform) ? (
            <Button
              variant="outlined"
              style={{ textTransform: 'none', color: 'grey.12' }}
              color="grey"
              size="lg"
              onClick={() => setActivePlatform(platform)}
            >
              {`Accedi ad ${platformToDisplayName[platform]}`}
            </Button>
          ) : (
            <Button
              variant="plain"
              style={{ textTransform: 'none' }}
              color="grey"
              size="lg"
              onClick={() => setActivePlatform(undefined)}
            >
              Indietro
            </Button>
          )}
        </Page.Footer>
      </Page>
    )
  );
};

PlatformDetailsView.displayName = 'PlatformDetailsView';
export default PlatformDetailsView;
