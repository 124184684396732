import { CassettoApiIcon } from '@cassetto/icons';
import React, { useState } from 'react';
import FullScreenCard from 'src/requests/components/FullScreenCard';
import { Page } from '../../../_shared/components/Page';
import { PageHeading } from '../../../_shared/components/PageHeading';
import RequestDataRetriever from '../../../requests/views/DataRequest';
import { DemoFooter } from '../../components/DemoFooter';
import { DemoFunnel } from '../../components/DemoFunnel';
import { Ribbon } from '../../components/Ribbon';

export const RequestContext = React.createContext<string | undefined>(
  undefined,
);

const DemoRequestCreator: React.FC = () => {
  const [requestId, setRequestId] = useState<string | undefined>(undefined);

  if (requestId) {
    return (
      <RequestContext.Provider value={requestId}>
        <Ribbon text="DEMO" />
        <RequestDataRetriever />
        {/* TODO: Handle DemoFooter on mobile */}
        <DemoFooter content="Stai agendo come UTENTE" color="green" />
      </RequestContext.Provider>
    );
  }

  return (
    <>
      <Ribbon text="DEMO" />
      <FullScreenCard>
        <Page
          variant="modal"
          style={{
            gap: 0,
            paddingTop: ({ spacing }) => ({
              xs: spacing('sm-8'),
              sm: spacing('sm-8'),
              md: 0,
            }),
          }}
        >
          <PageHeading title={<CassettoApiIcon />} />
          <Page.Content>
            <DemoFunnel onDemoCreated={setRequestId} />
          </Page.Content>
        </Page>
      </FullScreenCard>
      <DemoFooter content="Stai agendo come BANCA" color="blue" />
    </>
  );
};

DemoRequestCreator.displayName = 'DemoRequestCreator';
export default DemoRequestCreator;
