import React, { createContext, useEffect, useState } from 'react';
import io, { Socket } from 'socket.io-client';
import { config } from '@xtreamsrl/react-config';
import {
  C2SEvent,
  LoginStep,
  Platforms,
  SupportedProvider,
  TwoFactorHandling,
} from '../types';
import { useRequestId } from './useRequestId';

export const LoginContext = createContext<{
  socket: Socket | null;
  setStep: (step: number) => void;
  retry: () => void;
  platform: Platforms;
  provider: SupportedProvider;
} | null>(null);

export function useLogin(platform: Platforms) {
  const requestId = useRequestId();
  const [selectedProvider, setSelectedProvider] =
    React.useState<SupportedProvider>();
  const [socket, setSocket] = useState<Socket | null>(null);

  const [step, setStep] = useState<number>(LoginStep.authentication);
  const [twoFactorHandling, setTwoFactorHandling] = useState<TwoFactorHandling>(
    {
      twoFactors: {
        notification: { available: false },
        sms: { available: false },
        otp: { available: false },
      },
    },
  );

  const selectProvider = (spid: SupportedProvider) => {
    socket?.emit(C2SEvent.PROVIDER_SELECTION, {
      provider: spid,
      requestId: requestId,
      platform: platform,
    });
    setSelectedProvider(spid);
  };

  const retry = () => {
    setSelectedProvider(undefined);
    setStep(LoginStep.authentication);
  };

  useEffect(() => {
    const socketInstance = io(config.baseUrl, {
      query: { requestId, platform },
      transports: ['websocket'],
    });
    setSocket(socketInstance);

    // Cleanup function
    return () => {
      socketInstance.close();
    };
  }, [requestId, platform]);

  return {
    selectedProvider,
    selectProvider,
    step,
    setStep,
    twoFactorHandling,
    setTwoFactorHandling,
    socket,
    retry,
  };
}
