import React, { PropsWithChildren, useState } from 'react';
import useIsMobile from '../../../_shared/hooks/useIsMobile';
import { Platforms } from '../../types';
import { PlatformDetailsView } from '../../views/mobile/PlatformDetailsView';
import { PlatformsView } from '../../views/PlatformsView';
import Login from '../Login';

type Props = {
  nextStep: () => void;
  setToken: (token: string) => void;
};

const MultiplatformLogin: React.FC<PropsWithChildren<Props>> = ({
  nextStep,
  setToken, // This will be used only to get to the "Invio Documenti step" when the user decides to
}) => {
  const isMobile = useIsMobile();
  const [goToLogin, setGoToLogin] = useState(false);
  const [activePlatform, setActivePlatform] = React.useState<
    Platforms | undefined
  >(undefined);
  const [lastSuccessfullyAccessed, setLastSuccessfullyAccessed] =
    React.useState<Platforms | undefined>();

  const goToPlatforms = () => {
    setActivePlatform(undefined);
    setGoToLogin(false);
  };

  if (!activePlatform) {
    return (
      <PlatformsView
        setActivePlatform={setActivePlatform}
        lastSuccessfullyAccessedPlatform={lastSuccessfullyAccessed}
        endProcedure={nextStep}
      />
    );
  }

  if (isMobile && activePlatform && !goToLogin) {
    return (
      <PlatformDetailsView
        setActivePlatform={(pl) => {
          if (!pl) {
            goToPlatforms();
            return;
          }
          setGoToLogin(true);
        }}
        platform={activePlatform}
        lastSuccessfullyAccessedPlatform={lastSuccessfullyAccessed}
      />
    );
  }

  return (
    (goToLogin || !isMobile) && (
      <Login
        platform={activePlatform}
        unsetPlatform={() => {
          if (isMobile) {
            setGoToLogin(false);
            return;
          }
          setActivePlatform(undefined);
        }}
        onLoginSuccess={(token: string) => {
          setToken(token);
          setLastSuccessfullyAccessed(activePlatform);
          goToPlatforms();
        }}
        onLoginFailure={goToPlatforms}
      />
    )
  );
};

MultiplatformLogin.displayName = 'MultiplatformLogin';
export default MultiplatformLogin;
