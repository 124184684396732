import {
  DocumentTypeDownloadStatus,
  platformStatus,
  PlatformStatus,
} from '../../types';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { ErrorIcon, SuccessIcon, WarningIcon } from '@cassetto/icons';
import { Spinner } from '@xtreamsrl/react-ui-kit/Spinner';
import React from 'react';

interface Props {
  docStatus: DocumentTypeDownloadStatus['status'] | undefined;
  platfromStatus: PlatformStatus;
  hasJustBeenAccessed: boolean;
}

const DocumentStatusIcon: React.FC<Props> = (props) => {
  return (
    <>
      {props.docStatus === 'COMPLETED' && (
        <Icon>
          <SuccessIcon />
        </Icon>
      )}
      {props.docStatus === 'ERROR' && (
        <Icon>
          <ErrorIcon />
        </Icon>
      )}
      {props.docStatus === 'PARTIAL' && (
        <Icon>
          <WarningIcon />
        </Icon>
      )}
      {!props.docStatus &&
        (props.platfromStatus === platformStatus.DOWNLOADING ||
          props.hasJustBeenAccessed) && (
          <Spinner state="indeterminate" size={20} />
        )}
    </>
  );
};

DocumentStatusIcon.displayName = 'DocumentStatusIcon';
export default DocumentStatusIcon;
