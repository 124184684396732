import { config } from '@xtreamsrl/react-config';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import { Page } from '../../_shared/components/Page';
import { PageHeading } from '../../_shared/components/PageHeading';
import { isInFinalStatus } from '../../_shared/utils/utils';
import Callout from '../components/Callout/Callout';
import { PlatformWithDocsRecap } from '../components/PlatformWithDocsRecap';
import { useRequestData } from '../hooks/useRequestData';
import { Platforms } from '../types';
import { isStateCreatedOrAuthInProgress } from '../utils';

export function PlatformsView(props: {
  setActivePlatform: (platform: Platforms | undefined) => void;
  lastSuccessfullyAccessedPlatform: Platforms | undefined;
  endProcedure: () => void;
}) {
  const data = useRequestData({
    lastSuccessfullyAccessedPlatform: props.lastSuccessfullyAccessedPlatform,
  });

  const isSafeToEnd = data.platformAndDocuments.every(
    // the ! is safe because of the check performed in the api when the data is fetched
    (p) => isInFinalStatus(data.platformStatus[p.platform]!.status),
  );

  const showShareLink =
    data.isLinkShareable &&
    data.platformAndDocuments
      .map((p) => p.platform)
      .some((p) => !isInFinalStatus(data.platformStatus[p]!.status));

  return (
    <Page>
      {/* TODO: Add info right-button component here when ready */}
      <PageHeading
        title="Accesso e Recupero Documenti"
        subtitle="I documenti richiesti potrebbero essere disponibili su diverse
          piattaforme. Sarà necessario accedere a ciascuna piattaforma
          separatamente per recuperarli"
      />

      <Page.Content>
        <Flex
          height="100%"
          direction="column"
          justifyContent="space-between"
          gap="sm-8"
        >
          <Flex height="100%" direction="column" gap="sm-8">
            {data.platformAndDocuments.map((p, i) => (
              <PlatformWithDocsRecap
                key={p.platform}
                requestFrom={data.requestFrom}
                platform={p.platform}
                // the ! is safe because of the check performed in the api when the data is fetched
                status={data.platformStatus[p.platform]!.status}
                documents={p.documents}
                downloadReport={data.downloadReport}
                setActivePlatform={props.setActivePlatform}
                hasJustBeenAccessed={
                  p.platform === props.lastSuccessfullyAccessedPlatform
                }
                open={
                  p.platform !== props.lastSuccessfullyAccessedPlatform &&
                  isStateCreatedOrAuthInProgress(
                    data.platformStatus[p.platform]!.status,
                  ) &&
                  !data.platformAndDocuments
                    .slice(0, i)
                    .some(
                      (prevP) =>
                        isStateCreatedOrAuthInProgress(
                          data.platformStatus[prevP.platform]!.status,
                        ) &&
                        prevP.platform !==
                          props.lastSuccessfullyAccessedPlatform,
                    )
                }
              />
            ))}
          </Flex>

          {showShareLink && (
            <Callout
              status="info"
              text="Non disponi delle credenziali per accedere a queste piattaforme?"
            >
              <Typography variant="body/xs/regular" color="grey.11">
                Se non si possiedono le credenziali per accedere alle
                piattaforme, potrebbe essere necessario condividere il link con
                un collega che disponga dei permessi necessari.
              </Typography>
              <Box>
                <Button
                  variant="plain"
                  color="info"
                  size="sm"
                  rightIcon={<Icon name="Paperclip" />}
                  onClick={() => {
                    void navigator.clipboard.writeText(window.location.href);
                  }}
                >
                  Condividi Link alla Piattaforma
                </Button>
              </Box>
            </Callout>
          )}
        </Flex>
      </Page.Content>

      <Page.Footer
        aboveButtonsContent={
          <>
            {config.isDemo && (
              <Typography
                variant="body/xs/bold"
                color="grey.11"
                textAlign="center"
              >
                Quando NON in DEMO
              </Typography>
            )}
            <Typography
              variant="body/xs/regular"
              color="grey.11"
              textAlign="center"
            >
              I documenti verranno condivisi con {data.requestFrom.name}{' '}
              progressivamente e in maniera automatica dopo l'accesso alle
              rispettive piattaforme.
            </Typography>

            {isSafeToEnd && config.isDemo ? (
              <Typography
                variant="body/xs/regular"
                color="grey.11"
                textAlign="center"
              >
                Avrai modo di visualizzare i documenti scaricati una volta
                terminata la procedura su tutte le piattaforme.
              </Typography>
            ) : null}
          </>
        }
      >
        {isSafeToEnd ? (
          <Button
            onClick={props.endProcedure}
            style={{
              textTransform: 'none',
              width: { xs: '100%', sm: '100%', md: 'auto' },
            }}
          >
            {config.isDemo
              ? `Visualizza i documenti scaricati`
              : `Termina procedura in sicurezza`}
          </Button>
        ) : config.isDemo ? (
          <>
            <Typography
              variant="body/xs/bold"
              color="grey.11"
              textAlign="center"
            >
              Al momento SOLO in DEMO
            </Typography>
            <Typography
              variant="body/xs/regular"
              color="grey.11"
              textAlign="center"
            >
              Avrai modo di visualizzare i documenti scaricati una volta
              terminata la procedura su tutte le piattaforme.
            </Typography>
          </>
        ) : null}
      </Page.Footer>
    </Page>
  );
}
