import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import React from 'react';
import { createPortal } from 'react-dom';
import { Page } from 'src/_shared/components/Page';
import useIsMobile from '../../../../_shared/hooks/useIsMobile';
import { usePagePortal } from '../../../../_shared/hooks/usePagePortal';
import { providerToDisplayName } from '../../../../_shared/utils/utils';
import {
  AuthorisationAndFinalisationInnerStep,
  useAuthorisationAndFinalisationEventsHandler,
} from '../../../hooks/useAuthorisationAndFinalisationEventsHandler';
import { SupportedProvider } from '../../../types';
import Callout from '../../Callout/Callout';
import { FinalisationStep } from './index';

type AuthorisationAndFinalisationStepProps = {
  onLoginSuccess: (token: string) => void;
  onLoginFailure: () => void;
  selectedProvider: SupportedProvider;
};

const getCurrentStep = (
  steps: ReturnType<
    typeof useAuthorisationAndFinalisationEventsHandler
  >['innerSteps'],
): AuthorisationAndFinalisationInnerStep | null => {
  if (steps.authentication === 'loading' || steps.authentication === 'error')
    return 'authentication';
  if (steps.authorisation === 'loading' || steps.authorisation === 'error')
    return 'authorisation';
  if (
    steps.headersAndCookies === 'loading' ||
    steps.headersAndCookies === 'error'
  )
    return 'headersAndCookies';
  return null;
};

const getDescription = (
  selectedProvider: SupportedProvider,
  specificError?: string,
) => {
  switch (specificError) {
    case 'ACTION_NEEDED_ON_INPS_CONTACTS':
      return (
        <>
          L'accesso è andato a buon fine ma per recuperare i tuoi documenti{' '}
          <b>è necessario aggiornare/verificare i tuoi contatti</b> sul{' '}
          <a href="https://www.inps.it" target="_blank">
            sito INPS
          </a>
          . Ritenta l'accesso dopo aver manualmente risolto il problema.
        </>
      );
    default:
      return (
        <>
          Questo potrebbe essere dovuto a: provider{' '}
          <strong>
            {providerToDisplayName[selectedProvider]} momentaneamente non
            disponibile
          </strong>
          , <strong>credenziali errate</strong> o{' '}
          <strong>mancanza dei permessi</strong> per accedere alla piattaforma o
          al dato richiesto.
        </>
      );
  }
};

const getActionText = (specificError?: string) => {
  switch (specificError) {
    case 'ACTION_NEEDED_ON_INPS_CONTACTS':
      return 'Ho aggiornato i contatti';
    default:
      return 'Riprova';
  }
};

const AuthorisationAndFinalisationStep: React.FC<
  AuthorisationAndFinalisationStepProps
> = ({ onLoginSuccess, onLoginFailure, selectedProvider }) => {
  const isMobile = useIsMobile();
  const pagePortalEl = usePagePortal('modal');
  const { innerSteps, retry, specificError } =
    useAuthorisationAndFinalisationEventsHandler({
      onLoginSuccess,
    });

  const error = Object.values(innerSteps).some(
    (innerStep) => innerStep === 'error',
  );

  const currentStep = getCurrentStep(innerSteps);

  return (
    <Flex
      flex={1}
      alignItems="center"
      direction="column"
      justifyContent="center"
      gap="sm-6"
    >
      {isMobile && currentStep ? (
        <FinalisationStep step={currentStep} state={innerSteps[currentStep]} />
      ) : !specificError ? ( // If there is a specific error, we don't want to show the steps
        <>
          {Object.keys(innerSteps).map((step) => (
            <FinalisationStep
              step={step as AuthorisationAndFinalisationInnerStep}
              state={innerSteps[step as AuthorisationAndFinalisationInnerStep]}
            />
          ))}
        </>
      ) : null}
      {error && (
        <Callout
          status="error"
          text="Si è verificato un errore durante l'accesso alla piattaforma"
        >
          <Typography variant="body/sm/regular">
            {getDescription(selectedProvider, specificError)}
          </Typography>
        </Callout>
      )}
      {error &&
        pagePortalEl &&
        createPortal(
          <Page.Footer variant="modal">
            {/* The style is required due to the fact that mui adds absolute positioning for buttons inside the modal */}
            <Button
              size="lg"
              color="info"
              onClick={retry}
              style={{
                position: 'relative !important',
                top: '0 !important',
                right: '0 !important',
              }}
            >
              {getActionText(specificError)}
            </Button>
            <Button
              variant="plain"
              size="lg"
              color="grey"
              onClick={onLoginFailure}
              leftIcon={<Icon name="ArrowLeft" />}
              style={{
                position: 'relative !important',
                top: '0 !important',
                right: '0 !important',
              }}
            >
              Accedi ad un'altra piattaforma
            </Button>
          </Page.Footer>,
          pagePortalEl,
        )}
    </Flex>
  );
};

AuthorisationAndFinalisationStep.displayName =
  'AuthorisationAndFinalisationStep';
export default AuthorisationAndFinalisationStep;
