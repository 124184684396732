import { useEffect } from 'react';

const handleBeforeUnload = (e: BeforeUnloadEvent) => {
  e.preventDefault();
  return (e.returnValue = 'Vuoi davvero chiudere la pagina?');
};

export function useRefreshPrevention(disabled?: boolean) {
  useEffect(() => {
    if (disabled) return;

    // Add the event listener when the component is mounted
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [disabled]);
}
