import { useContext, useEffect, useState } from 'react';
import { LoginStep, S2CEvent } from '../types';
import { LoginContext } from './useLogin';

export type AuthorisationAndFinalisationInnerStep =
  | 'authentication'
  | 'authorisation'
  | 'headersAndCookies';

export type AuthorisationAndFinalisationInnerState =
  | 'todo'
  | 'loading'
  | 'success'
  | 'error';

export function useAuthorisationAndFinalisationEventsHandler({
  onLoginSuccess,
}: {
  onLoginSuccess: (token: string) => void;
}) {
  const loginContext = useContext(LoginContext);
  if (!loginContext || !loginContext.socket)
    throw new Error(
      'useAuthorisationAndFinalisationEventsHandler must be used within a LoginContext and socket cannot be null.',
    );

  const { socket, setStep, retry } = loginContext;
  const [innerSteps, setInnerSteps] = useState<
    Record<
      AuthorisationAndFinalisationInnerStep,
      AuthorisationAndFinalisationInnerState
    >
  >({
    authentication: 'success',
    authorisation: 'loading',
    headersAndCookies: 'todo',
  });
  // this has been introduced to handle the specific error of the user mismatch and customise the message (e.g. for INPS contacts to be updated)
  const [specificError, setSpecificError] = useState<string | undefined>();

  useEffect(() => {
    socket.on(S2CEvent.AUTHORISED, () => {
      setInnerSteps((prev) => ({
        ...prev,
        authorisation: 'success',
        headersAndCookies: 'loading',
      }));
      // The user will still see the finalisation step
    });
    socket.on(
      S2CEvent.HEADERS_AND_COOKIES_RETRIEVAL_SUCCESS,
      (event: { message: string; token: { access_token: string } }) => {
        console.log(event);
        setInnerSteps((prev) => ({
          ...prev,
          headersAndCookies: 'success',
        }));
        onLoginSuccess(event.token.access_token);
        socket.close();
      },
    );
    socket.on(S2CEvent.ERROR, (data: { code: string; message: string }) => {
      console.error('Error received from the server:', data);
      // TODO: display the correct message to the user
      switch (data.code) {
        case 'HEADERS_AND_COOKIES_RETRIEVAL_FAILED':
          setInnerSteps((prev) => ({
            ...prev,
            headersAndCookies: 'error',
          }));
          break;
        case 'USER_MISMATCH':
        case 'UNAUTHORISED':
          setInnerSteps((prev) => ({
            ...prev,
            authorisation: 'error',
          }));
          break;
        case 'ACTION_NEEDED_ON_INPS_CONTACTS': {
          setInnerSteps((prev) => ({
            ...prev,
            authorisation: 'error',
          }));
          setSpecificError(data.code);
          break;
        }
        default:
          setStep(LoginStep.error);
      }
    });

    return () => {
      socket.off(S2CEvent.AUTHORISED);
      socket.off(S2CEvent.HEADERS_AND_COOKIES_RETRIEVAL_SUCCESS);
      socket.off(S2CEvent.ERROR);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { innerSteps, setStep, retry, specificError };
}
