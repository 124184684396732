import { FormTextInput } from '@xtreamsrl/react-form-input-kit/FormTextInput';
import { FormProvider } from '@xtreamsrl/react-forms';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Tabs } from '@xtreamsrl/react-ui-kit/Tabs';
import React, { PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import { Page } from 'src/_shared/components/Page';
import { usePagePortal } from '../../../_shared/hooks/usePagePortal';
import {
  FiscalData,
  tabs,
  useFiscalDataForm,
} from '../../hooks/useFiscalDataForm';

type Props = {
  onSubmit: (fiscalData: FiscalData) => void;
  back: () => void;
};

const FiscalDataForm: React.FC<PropsWithChildren<Props>> = ({
  onSubmit,
  back,
}) => {
  const { fiscalDataForm, handleFiscalDataSubmit, currentTab, setCurrentTab } =
    useFiscalDataForm(onSubmit);
  const pagePortalEl = usePagePortal('modal');

  return (
    <FormProvider enableDevTools={false} {...fiscalDataForm.formProps}>
      <form
        style={{ width: '100%' }}
        onSubmit={(event) => {
          handleFiscalDataSubmit(event).catch((e) => {
            console.error('Error performing fiscal data submission', e);
          });
        }}
      >
        <Tabs
          currentTab={currentTab}
          fullWidth
          onChange={(_, t) => {
            setCurrentTab(t);
            fiscalDataForm.formProps.setValue('target', tabs[t].code);
          }}
        >
          <Tabs.Tab value={0}>{tabs[0].name}</Tabs.Tab>
          <Tabs.Tab value={1}>{tabs[1].name}</Tabs.Tab>
        </Tabs>
        {Object.keys(tabs).map((key) => {
          const index = Number(key);
          return (
            <Tabs.Panel index={index} currentTab={currentTab} key={index}>
              <Flex direction="column" marginTop="md-1">
                {tabs[index].code === 'PF' ? (
                  <FormTextInput
                    className="uppercase"
                    autoComplete="on"
                    label="Codice fiscale"
                    name="fiscalCode"
                  />
                ) : (
                  <>
                    <FormTextInput
                      autoComplete="on"
                      label="Partita IVA"
                      name="vatNumber"
                    />
                    <FormTextInput label="Nome Azienda" name="name" />
                  </>
                )}
                {pagePortalEl &&
                  createPortal(
                    <Page.Footer variant="modal">
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          handleFiscalDataSubmit().catch((error) => {
                            console.error('Failed to submit form:', error);
                          });
                        }}
                      >
                        Avanti
                      </Button>
                      <Button
                        variant="plain"
                        onClick={(e) => {
                          e.preventDefault();
                          back();
                        }}
                      >
                        Indietro
                      </Button>
                    </Page.Footer>,
                    pagePortalEl,
                  )}
              </Flex>
            </Tabs.Panel>
          );
        })}
      </form>
    </FormProvider>
  );
};

FiscalDataForm.displayName = 'FiscalDataForm';
export default FiscalDataForm;
