import { useQuery } from '@tanstack/react-query';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { ThemeProvider } from '@xtreamsrl/react-ui-kit/theme';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import React, { useState } from 'react';
import { createCustomTheme } from '../../../theme';
import { CassettoException } from '../../apis/getRequestInfo';
import { optionsBuilder } from '../../asyncHooks/getRequestInfo';
import FullScreenCard from '../../components/FullScreenCard';
import LeavingCard from '../../components/LeavingCard';
import RequestInfo from '../../components/RequestInfo';
import { useRequestId } from '../../hooks/useRequestId';
import { EntireProcessContainer } from './EntireProcessContainer';

const RequestDataRetriever: React.FC = () => {
  // TODO: A way to check if terms have been accepted is required
  const [areTermsAccepted, setTermsAccepted] = useState<boolean | undefined>(
    undefined,
  );
  const requestId = useRequestId();

  const { data, isError, error } = useQuery({
    ...optionsBuilder(requestId),
    select: (data) => ({
      requestFrom: data.requestFrom,
      target: data.target,
    }),
  });

  if (data) {
    const theme = createCustomTheme(data.requestFrom.color);
    return (
      <ThemeProvider theme={theme}>
        {areTermsAccepted === true && (
          <EntireProcessContainer
            requestFrom={data.requestFrom}
            target={data.target}
          />
        )}
        {areTermsAccepted === false && (
          <LeavingCard
            requestFrom={data.requestFrom}
            heading="Autorizzazione non concessa"
            text="La procedura è stata terminata poiché non hai accettato i
                termini e le condizioni. Se desideri procedere, ti invitiamo a
                cliccare sul pulsante sottostante. Altrimenti, puoi chiudere la
                finestra."
          >
            <Button
              variant="outlined"
              onClick={() => setTermsAccepted(undefined)}
              size="lg"
              style={{ width: { xs: '100%', sm: '100%', md: 'fit-content' } }}
            >
              Torna indietro
            </Button>
          </LeavingCard>
        )}
        {areTermsAccepted === undefined && (
          <RequestInfo nextStep={(value: boolean) => setTermsAccepted(value)} />
        )}
      </ThemeProvider>
    );
  }

  if (isError) {
    if (error instanceof CassettoException)
      return (
        <FullScreenCard>
          <Flex direction="column" gap="md-1">
            <Typography variant="body/xl/bold">
              {error.code === 410
                ? 'Richiesta scaduta'
                : 'Richiesta non trovata'}
            </Typography>

            <Typography variant="body/sm/regular">
              {error.code === 410
                ? 'La richiesta è scaduta, non è più possibile visualizzarla. Contatta il supporto per ulteriori informazioni.'
                : 'La richiesta non è presente nei nostri sistemi. Controlla di star accedendo alla pagina corretta. Altrimenti contatta il supporto.'}
            </Typography>
          </Flex>
        </FullScreenCard>
      );
  }

  return (
    <Flex
      width="100vw"
      height="100svh"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="body/base/regular" textAlign="center">
        Caricamento...
      </Typography>
    </Flex>
  );
};

RequestDataRetriever.displayName = 'DataRequest';

export default RequestDataRetriever;
