import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import React, { PropsWithChildren } from 'react';

type Props = {
  text: string;
};

const Ribbon: React.FC<PropsWithChildren<Props>> = ({ text }) => {
  return (
    <Box
      padding="sm-3"
      width="300px"
      overflow="hidden"
      style={{
        width: 300,
        backgroundColor: '#14B62B',
        position: 'fixed',
        zIndex: 3,
        transform: 'rotate(45deg)',
        top: { md: 50, xs: 30, sm: 30 },
        right: { md: -75, xs: -100, sm: -100 },
      }}
    >
      <Typography
        variant="body/base/semibold"
        color="white"
        textAlign="center"
        textTransform="uppercase"
        style={{ overflow: 'hidden' }}
      >
        {text}
      </Typography>
    </Box>
  );
};

Ribbon.displayName = 'Ribbon';
export default Ribbon;
