export interface BEDataRequest {
  id: string;
  requestFrom: Company;
  target: Target;
  documents: Document[];
  loginAuthPlatforms: LoginAuthPlatforms;
  platformStatus: Partial<Record<Platforms, PlatformStatusInfo>>;
  downloadReport: DownloadReport;
  authenticated: boolean;
  purpose: string;
  isLinkShareable: boolean;
}

export interface ErroredDownloadStatus {
  status: 'ERROR';
}

export interface CompletedOrPartialDownloadStatus {
  status: 'PARTIAL' | 'COMPLETED';
  partial: number;
  completed: number;
  downloaded: number;
  total: number;
}

export type DocumentTypeDownloadStatus =
  | ErroredDownloadStatus
  | CompletedOrPartialDownloadStatus;

export interface DownloadReportElement {
  type: RequestDocumentType;
  documentTypeDownloadStatus: DocumentTypeDownloadStatus;
}

export type DownloadReport = DownloadReportElement[];

export const platformStatus = {
  /**
   * The request has been created but no further action has been taken on the single platform.
   */
  CREATED: 'CREATED',
  /**
   * The applicant has visited the link and has accepted the privacy policy for a certain platform.
   */
  AUTH_IN_PROGRESS: 'AUTH_IN_PROGRESS',
  /**
   * The applicant has failed the platform login.
   */
  AUTH_FAILED: 'AUTH_FAILED',
  /**
   * The applicant has successfully completed the platform login.
   */
  AUTH_SUCCESS: 'AUTH_SUCCESS',
  /**
   * The applicant has successfully completed the platform login and the download has started for the documents of the platform.
   */
  DOWNLOADING: 'DOWNLOADING',
  /**
   * The applicant has successfully completed the platform login but some documents out of all found documents have not been downloaded correctly.
   */
  DOWNLOAD_PARTIAL: 'DOWNLOAD_PARTIAL',
  /**
   * The applicant has successfully completed the platform login and downloaded all documents.
   */
  DOWNLOAD_COMPLETED: 'DOWNLOAD_COMPLETED',
  /**
   * The applicant has successfully completed the platform login but none of the documents has been downloaded correctly.
   */
  DOWNLOAD_FAILED: 'DOWNLOAD_FAILED',
} as const;

export type PlatformStatus = keyof typeof platformStatus;

export interface PlatformStatusInfo {
  status: PlatformStatus;
  updatedAt?: string;
}

export interface DataRequest {
  id: string;
  requestFrom: Company;
  target: Target;
  documents: Document[];
  loginAuthPlatforms: LoginAuthPlatforms;
  platformAndDocuments: PlatformAndDocuments[];
  platformStatus: Partial<Record<Platforms, PlatformStatusInfo>>;
  downloadReport: DownloadReport;
  authenticated: boolean;
  purpose: string;
  isLinkShareable: boolean;
}

export enum Platforms {
  cassetto = 'cassetto',
  riscossione = 'riscossione',
  inps = 'inps',
}

export const platformToDisplayName = {
  [Platforms.cassetto]: 'Agenzia delle Entrate',
  [Platforms.riscossione]: 'AdE | Riscossione',
  [Platforms.inps]: 'INPS',
} satisfies Record<Platforms, string>;

export const platformToLogo = {
  [Platforms.cassetto]: '/assets/ade.png',
  [Platforms.riscossione]: '/assets/ade-riscossione.png',
  [Platforms.inps]: '/assets/inps.png',
} satisfies Record<Platforms, string>;

export type LoginAuthPlatforms = Partial<
  Record<
    Platforms,
    {
      docs: RequestDocumentType[];
    }
  >
>;

export type PlatformAndDocuments = {
  platform: Platforms;
  documents: Document[];
};

export interface Company {
  logo: string;
  name: string;
  color: string;
  privacyUrl: string;
}

export interface Physical {
  type: 'PF';
  fiscalCode: string;
  name: string;
}

export interface Legal {
  type: 'PG';
  vatNumber: string;
  name: string;
}

export type Target = Physical | Legal;

export interface LastNMonthsFilterRequest {
  type: 'lastNMonths';
  numberOfMonths: number;
}

export interface YearsFilterRequest {
  type: 'years';
  years: number[];
}

export interface LastNYearsFilterRequest {
  type: 'lastNYears';
  numberOfYears: number;
}

export interface DateRangeFilterRequest extends DateRange {
  type: 'dateRange';
}

export interface FatturaFilterWhere {
  /**
   * Filter invoices by direction
   * @example 'received'
   * @enum ['emitted', 'received']
   * @default ['emitted', 'received']
   */
  direction?: 'emitted' | 'received';
}

interface DateRange {
  from: string;
  to: string;
}

interface F24RiepilogativoFilterOr {
  dates: DateRangeFilterRequest | LastNYearsFilterRequest;
  codiceTributo: string[];
  tipologiaTributo: 'C' | 'D';
  prefisso?: string;
}

export interface F24RiepilogativoFilterWhere {
  or: F24RiepilogativoFilterOr[];
}

export function isF24RiepilogativoFilterWhere(
  where: object,
): where is F24RiepilogativoFilterWhere {
  return 'or' in where;
}

type DatesFilter =
  | LastNMonthsFilterRequest
  | YearsFilterRequest
  | LastNYearsFilterRequest
  | DateRangeFilterRequest;

interface GenericDocument {
  type: RequestDocumentType;
  filters?: {
    dates?: DatesFilter;
    where?: never;
  };
}

interface FatturaDocument {
  type: typeof documentTypes.FATTURA;
  filters?: {
    dates?:
      | YearsFilterRequest
      | LastNYearsFilterRequest
      | DateRangeFilterRequest;
    where?: FatturaFilterWhere;
  };
}

interface F24RiepilogativoDocument {
  type: typeof documentTypes.F24_RIEPILOGATIVO;
  filters?: {
    dates?: never;
    where?: F24RiepilogativoFilterWhere;
  };
}

export type Document =
  | GenericDocument
  | FatturaDocument
  | F24RiepilogativoDocument;

export type FileResponse = {
  DICHIARAZIONE: { year: string; link: string }[];
};

export const LoginStep = {
  error: -1,
  authentication: 0,
  twoFactorSelection: 1,
  otpInput: 2,
  authorisationAndFinalisation: 3,
} as const;

export enum SupportedSpid {
  posteId = 'posteid',
  sielteId = 'sielteid',
  lepidaId = 'lepidaid',
  namirialId = 'namirialid',
  etnaId = 'etnaid',
  arubaId = 'arubaid',
  infocamereId = 'infocamereid',
  infocertId = 'infocertid',
  spiditalia = 'spiditalia',
  timId = 'timid',
  intesiId = 'intesiid',
  teamsystem = 'teamsystem',
}

export enum SupportedOtherAuth {
  cie = 'cie',
}

export type SupportedProvider = SupportedSpid | SupportedOtherAuth;

export type AuthenticationData = {
  username: string;
  password: string;
};

export interface CompanyData {
  fatture: Fatture[];
  totaleFatture: number;
  limiteBloccoTotaleFatture: number;
  dataRicercaDa: string;
  dataRicercaA: string;
  label: Label;
  disclaimer: boolean;
  disabilitato: boolean;
  profiloDownload: boolean;
}

export const documentTypes = {
  F24: 'F24',
  ISA: 'ISA',
  ANAGRAFICA: 'ANAGRAFICA',
  DICHIARAZIONE_IVA: 'DICHIARAZIONE_IVA',
  U60: 'U60',
  IRAP: 'IRAP',
  MODELLO770: 'MODELLO770',
  LIQUIDAZIONE_IVA: 'LIQUIDAZIONE_IVA',
  FATTURA: 'FATTURA',
  CERTIFICAZIONE_UNICA: 'CERTIFICAZIONE_UNICA',
  DICHIARAZIONE_REDDITI: 'DICHIARAZIONE_REDDITI',
  RIMBORSO: 'RIMBORSO',
  ESTRATTO_DI_RUOLO: 'ESTRATTO_DI_RUOLO',
  F24_RIEPILOGATIVO: 'F24_RIEPILOGATIVO',
  ESTRATTO_CONTRIBUTIVO: 'ESTRATTO_CONTRIBUTIVO',
  MODELLO730: 'MODELLO730',
  STORICO_BUSTE_PAGA: 'STORICO_BUSTE_PAGA',
} as const;

export type RequestDocumentType = keyof typeof documentTypes;

export interface Fatture {
  variazioni: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fatturePrecedenti: any[];
  denominazioneEmittente: string;
  denominazioneCliente: string;
  pivaCliente: string;
  cfCliente?: string;
  dataAccoglienzaFile: string;
  identificativoCliente: string;
  pivaEmittente: string;
  cfEmittente: string;
  decodificaTipoInvio: string;
  fileDownload: FileDownload;
  clienteFornitore: string;
  idFattura: string;
  tipoInvio: string;
  tipoDocumento: string;
  numeroFattura: string;
  dataFattura: string;
  imponibile: string;
  imposta: string;
  stato: string;
  statoVisto: number;
  dettaglio: boolean;
  dataConsegna: string;
  testoFattureConsegnate: string;
  idPaeseCedente: string;
  idPaeseCessionario: string;
  disclaimer: boolean;
  disabilitato: boolean;
  profiloDownload: boolean;
}

export interface FileDownload {
  revocaDownload: number;
  statoDiConsegna: number;
  statoFile: string;
  fileDownload: number;
  idInvio: string;
  presaVisione: number;
}

export interface Label {
  labelIdentificativoControparte: string;
  statoVisto: number;
  dettaglio: boolean;
  disclaimer: boolean;
  disabilitato: boolean;
  profiloDownload: boolean;
}

export enum C2SEvent {
  /**
   * Event sent to the server to start a new session with the selected SPID provider or CIE.
   */
  PROVIDER_SELECTION = 'providerSelection',
  /**
   * Event sent to the server to retrieve the QR code, if supported by the SPID provider.
   */
  CREDENTIALS = 'credentials',
  /**
   * Event sent to the server to select the notification as the two-factor authentication method.
   */
  NOTIFICATION = 'notification',
  /**
   * Event sent to the server to request an OTP via SMS.
   */
  SMS_REQUEST = 'smsRequest',
  /**
   * Event sent to the server to select OTP as two-factor authentication method, this is used if the user has multiple OTP generators, and the entire process cannot be handled by a single event
   */
  OTP_SELECT = 'otpSelect',
  /**
   * Event sent to the server to verify the OTP received via SMS, or the OTP of the authentication app when multiple OTP generators are available for the provider.
   */
  OTP_VERIFY = 'otpVerify',
  /**
   * Event sent to the server to refresh the QR code image.
   */
  QR_REFRESH = 'qrRefresh',
}

export enum S2CEvent {
  /**
   * Event sent to the client to notify that the spid provider has been correctly selected. The event will bring with it the QR code image if it is supported.
   */
  SESSION_STARTED = 'sessionStarted',
  /**
   * Event sent to the client to indicate that the login credentials were correct, so it is possible to proceed with the two-factor authentication.
   */
  CREDENTIALS_SUCCESS = 'credentialsSuccess',
  /**
   * Event sent to the client to indicate that the SMS has been sent, so it is necessary to provide the OTP received via SMS.
   */
  SMS_OTP_REQUIRED = 'smsOtpRequired',
  /**
   * Event sent to the client to indicate that the login process was successful.
   */
  LOGIN_COMPLETED = 'loginCompleted',
  /**
   * Event sent to the client to indicate that the user can access the request target data.
   */
  AUTHORISED = 'authorised',
  /**
   * Event sent to the client to indicate that the headers and cookies have been successfully retrieved.
   */
  HEADERS_AND_COOKIES_RETRIEVAL_SUCCESS = 'headersAndCookiesRetrievalSuccess',
  /**
   * Event sent to the client to indicate that an error occurred during the login process.
   */
  ERROR = 'error',
}

export enum TwoFactorAuth {
  NOTIFICATION = 'notification',
  SMS = 'sms',
  OTP = 'otp',
}

interface BaseTwoFactorDetails {
  available: boolean;
}

interface OTPDetails extends BaseTwoFactorDetails {
  options?: {
    otpGeneratorIds: string[];
  };
}

interface NotificationDetails extends BaseTwoFactorDetails {
  options?: {
    automaticallySent: boolean;
  };
}

type TwoFactorAuthAvailability = {
  [TwoFactorAuth.NOTIFICATION]: NotificationDetails;
  [TwoFactorAuth.SMS]: BaseTwoFactorDetails;
  [TwoFactorAuth.OTP]: OTPDetails;
};

export interface TwoFactorHandling {
  twoFactors: TwoFactorAuthAvailability;
  automaticallySelected?: TwoFactorAuth;
}
