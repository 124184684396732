import { Accordion as UIKitAccordion } from '@xtreamsrl/react-ui-kit/Accordion';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Card } from '@xtreamsrl/react-ui-kit/Card';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { styled } from '@xtreamsrl/react-ui-kit/styles';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import React from 'react';
import useIsMobile from '../../_shared/hooks/useIsMobile';
import {
  isInFinalStatus,
  toMoreDescriptiveName,
} from '../../_shared/utils/utils';
import {
  Company,
  Document,
  DownloadReport,
  Platforms,
  platformStatus,
  PlatformStatus,
  platformToDisplayName,
  platformToLogo,
} from '../types';
import { isStatePotentiallyChangingServerSide } from '../utils';
import { Badge } from './Badge';
import Callout from './Callout/Callout';
import { DocumentsList } from './DocumentsList';

const Accordion = styled(UIKitAccordion)(({ theme }) => ({
  padding: 0,
  borderRadius: '4px',
  borderColor: theme.palette.grey[6],

  '.MuiCollapse-root': {
    background: 'white',
    borderRadius: '0px 0px 6px 6px',
    borderTop: '1px solid',
    borderColor: theme.palette.grey[6],
  },
  '.Mui-expanded': {
    backgroundColor: theme.palette.grey[2],
  },
}));
Accordion.displayName = 'Accordion';

const Summary = styled(UIKitAccordion.Summary)(({ theme }) => ({
  padding: theme.spacing('sm-8'),
}));
Summary.displayName = 'Accordion.Summary';

export const PlatformWithDocsRecap: React.FC<{
  platform: Platforms;
  requestFrom: Company;
  status: PlatformStatus;
  documents: Document[];
  downloadReport: DownloadReport;
  setActivePlatform: (platform: Platforms) => void;
  hasJustBeenAccessed: boolean;
  open: boolean;
}> = ({
  platform,
  requestFrom,
  documents,
  status,
  downloadReport,
  setActivePlatform,
  hasJustBeenAccessed,
  open,
}) => {
  const isMobile = useIsMobile();

  const showAccessButton =
    !isInFinalStatus(status) &&
    status !== platformStatus.DOWNLOADING &&
    !hasJustBeenAccessed;

  const SummaryContent = (
    <Flex direction={isMobile ? 'column' : 'row'} gap="sm-8" overflow="hidden">
      <Flex width={isMobile ? '100%' : '44px'} justifyContent="space-between">
        <img
          src={platformToLogo[platform]}
          alt={`Logo ${platformToDisplayName[platform]}`}
          style={{
            width: '44px',
            height: '44px',
          }}
        />

        {isMobile && (
          <>
            {showAccessButton && (
              <Button variant="plain" size="sm">
                Start
              </Button>
            )}
            {(status === platformStatus.DOWNLOADING ||
              (hasJustBeenAccessed &&
                isStatePotentiallyChangingServerSide(status))) && (
              <Badge state="loading" />
            )}
            {status === platformStatus.DOWNLOAD_FAILED && (
              <Badge state="error" />
            )}
            {status === platformStatus.DOWNLOAD_COMPLETED && (
              <Badge state="success" />
            )}
            {status === platformStatus.DOWNLOAD_PARTIAL && (
              <Badge state="warning" />
            )}
          </>
        )}
      </Flex>
      <Flex direction="column" overflow="hidden">
        <Flex gap="sm-4" alignItems="center">
          <Typography variant="body/base/semibold">
            {platformToDisplayName[platform]}
          </Typography>
          {!isMobile && (
            <>
              {(status === platformStatus.DOWNLOADING ||
                (hasJustBeenAccessed &&
                  isStatePotentiallyChangingServerSide(status))) && (
                <Badge state="loading" />
              )}
              {status === platformStatus.DOWNLOAD_FAILED && (
                <Badge state="error" />
              )}
              {status === platformStatus.DOWNLOAD_COMPLETED && (
                <Badge state="success" />
              )}
              {status === platformStatus.DOWNLOAD_PARTIAL && (
                <Badge state="warning" />
              )}
            </>
          )}
        </Flex>
        <Typography variant="body/sm/regular" color="grey.11" noWrap>
          {documents.map((doc) => toMoreDescriptiveName(doc.type)).join(', ')}
        </Typography>
      </Flex>
    </Flex>
  );

  if (isMobile)
    return (
      <Card
        style={{ overflow: 'unset' }}
        onClick={() => setActivePlatform(platform)}
      >
        <Card.Content>{SummaryContent}</Card.Content>
      </Card>
    );

  return (
    <Accordion defaultExpanded={open}>
      <Summary>{SummaryContent}</Summary>

      <UIKitAccordion.Details>
        <Flex
          direction="column"
          paddingTop="0"
          paddingRight="lg-2"
          paddingBottom="sm-8"
          paddingLeft="lg-2"
          gap="sm-5"
        >
          <DocumentsList
            downloadReport={downloadReport}
            documents={documents}
            status={status}
            hasJustBeenAccessed={hasJustBeenAccessed}
          />
          {showAccessButton && (
            <Box alignSelf="flex-end">
              <Button
                variant="outlined"
                style={{ textTransform: 'none', color: 'grey.12' }}
                color="grey"
                size="lg"
                onClick={() => setActivePlatform(platform)}
              >
                {`Accedi ad ${platformToDisplayName[platform]}`}
              </Button>
            </Box>
          )}
          {isInFinalStatus(status) &&
            status !== platformStatus.DOWNLOAD_COMPLETED && (
              <Callout
                status={
                  status === platformStatus.DOWNLOAD_PARTIAL
                    ? 'warning'
                    : 'error'
                }
                text="Errore nel recupero dei documenti"
              >
                <Typography variant="body/sm/regular">
                  Non è stato possibile recuperare tutti i documenti richiesti.
                  Tuttavia,{' '}
                  <b>
                    la procedura potrà proseguire anche con la documentazione
                    parziale.
                  </b>{' '}
                  {requestFrom.name} ti contatterà in caso fosse necessario
                  fornire ulteriori informazioni.
                </Typography>
              </Callout>
            )}
        </Flex>
      </UIKitAccordion.Details>
    </Accordion>
  );
};
